<template>
  <div class="careers">
    <portal to="sticky-component" tag="div" name="sticky-component">
      <div class="tab-wrap">
        <tab-item :list="tab.list" :name="tab.name" v-model="tab.active" />
      </div>
    </portal>
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            이모션글로벌과 함께라면<br />
            그 어떤 것도 할 수 있고,<br />
            어떤 것이라도 될 수 있습니다.
          </div>
        </div>
        <div class="bg"></div>
      </div>
    </div>

    <div class="tab-contents">
      <div class="careers-wrap">
        <!-- //Culture -->
        <div class="careers-inner" v-show="tab.active === 'culture'">
          <div class="contents">
            <div class="title">
              하루의 가장 많은 시간을 보내는 공간,<br />
              <strong>더 좋은 업무 환경을 만들어 갑니다.</strong>
            </div>

            <div v-swiper:mySwiper2="swiperPanorama" class="panorama-swiper">
              <div class="swiper-wrapper swiper-area">
                <div
                  class="swiper-slide swiper-item"
                  v-for="(item, index) in careerController.companyView"
                  :key="index"
                >
                  <span class="thumb">
                    <img :src="imgUrl(item.pcImagePhysicalName)" alt="item.pcImageName" />
                  </span>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>

            <ul class="benefits-list">
              <li>
                <img src="@/assets/images/career/ico-benefit01.png" alt="" />
                <strong>법적 복리후생</strong>
                <p>
                  기본을 단단하게 지켜드려요!<br />
                  4대 보험 적용 및 퇴직연금 제도
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit02.png" alt="" />
                <strong>건강검진 지원</strong>
                <p>2년에 한 번씩 건강체크!<br />건강검진비용 지원</p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit03.png" alt="" />
                <strong>휴가제도</strong>
                <p>
                  열심히 일한 이글리안, 떠나라!<br />
                  연차 휴가제도 운영
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit04.png" alt="" />
                <strong>근무제도</strong>
                <p>
                  조금 더 여유있는 근무를 위한<br />
                  주 5일 근무 및 시차 출퇴근제
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit05.png" alt="" />
                <strong>생일자 혜택</strong>
                <p>
                  행복한 날을 더 행복하게,<br />
                  상품권 지급 & 조기퇴근 제도
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit06.png" alt="" />
                <strong>사내시설</strong>
                <p>
                  여유있고 쾌적한 근무를 위해!<br />
                  카페테리아, 회의실 및 휴게실 운영
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit07.png" alt="" />
                <strong>해피런치</strong>
                <p>
                  맛있는 식사, 조금 더 천천히 즐겨요~<br />
                  매일 점심시간 1시간 30분 운영
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit08.png" alt="" />
                <strong>복지포인트 지원</strong>
                <p>
                  자기개발과 문화생활을 위한<br />
                  복지포인트 지급
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit09.png" alt="" />
                <strong>출산 선물</strong>
                <p>
                  본인 또는 배우자 출산 시,<br />
                  출산 축하금 & 선물 지급
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit10.png" alt="" />
                <strong>장기근속 포상</strong>
                <p>
                  3년 / 6년 / 10년 단위로<br />
                  장기근속 포상금 지급
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit11.png" alt="" />
                <strong>인사 추천 제도</strong>
                <p>
                  좋은 사람있으면 소개시켜 주세요!<br />
                  인재 추천 시 추천인에게 포상금 지급
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit12.png" alt="" />
                <strong>경조사 지원</strong>
                <p>
                  기쁜 일과 슬픈 일은 모두 함께,<br />
                  경조휴가 및 경조금 지급
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit13.png" alt="" />
                <strong>택시비 지원</strong>
                <p>
                  조금 더 안전한 귀가와 근무를 위해<br />
                  야근 및 외근 시 택시비 지원
                </p>
              </li>
              <li>
                <img src="@/assets/images/career/ico-benefit14.png" alt="" />
                <strong>명절 선물</strong>
                <p>
                  행복한 명절을 더욱 풍성하게!<br />
                  설날 / 추석 명절 선물 지급
                </p>
              </li>
            </ul>
          </div>
        </div>
        <!-- Culture// -->

        <!-- //Apply -->
        <div class="careers-inner" v-show="tab.active === 'apply'">
          <div class="contents">
            <div class="job-type">
              <div class="title">
                <strong>이모션글로벌</strong>과 함께 치열하게 고민하고<br />
                나아갈 <strong>당신을 기다립니다.</strong>
              </div>
              <div class="sub-title">지원하고 싶은 분야를 선택해 주세요.</div>
              <accordion>
                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Consultant</div>
                  </template>
                  <template slot="accordion-content">
                    <li class="acc-expend">
                      <div class="title">
                        우리는 사용자 관점과<br />
                        경험을 중심으로<br />
                        트렌드를 선도해 나아갈<br />
                        컨설턴트를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img src="../../assets/images/career/consultant.webp" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>UX 프로젝트 참여 및 수행 경험(학과 프로젝트 포함)</li>
                              <li>정량적, 정성적 Research에 대한 이해를 바탕으로 한 수행 경험</li>
                              <li>UX 디자인 프로세스 프로젝트 경험</li>
                              <li>IA설계, 프로토타이핑 설계 경험</li>
                              <li>
                                UX 전략/컨셉 도출 및 개념 정의 등 해결 솔루션 제안 능력 있으신 분
                              </li>
                              <li>
                                User Research, Persona 정의, Customer Journey Map 등의 모델링 수행
                                능력 있으신 분
                              </li>
                              <li>마케팅 분야, 기술/디자인에 대한 깊은 이해</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>UX, HCI, 서비스 디자인 관련 전공자</li>
                                <li>사용성 테스트 진행 경험</li>
                                <li>UI 프로젝트 실무 컨트롤 및 커뮤니케이션 능력</li>
                                <li>데이터 기반 서비스 개선 및 문제해결 능력</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>유관 경력 5년 이상</li>
                              <li>컨설팅 및 UX설계 등 다양한 디지털 프로젝트 수행 경험</li>
                              <li>사용성 평가 기획 및 진행(UT, In-depth Interview, Survey) 경험</li>
                              <li>
                                디지털 프로젝트 컨설팅, 기획, 개발, 운영까지 전체 프로세스 총괄 경험
                              </li>
                              <li>
                                Customer & Business 이해 기반 사용자 리서치 주제, 정의, 설계, 분석
                                가능하신 분
                              </li>
                              <li>사용자 행동, 동기, 태도 분석을 통한 모델링 가능하신 분</li>
                              <li>UT 진행, 리서치 ,데이터 분석을 통한 UX개선점 도출 가능하신 분</li>
                              <li>대형 프로젝트를 주도적으로 매니징, 리딩할 수 있으신 분</li>
                              <li>
                                신규 서비스 발굴, 기획 가능하신 분(프로덕트 비전 및 전략, 로드맵
                                수립)
                              </li>
                              <li>여러 이니셔티브의 우선순위 선정 및 일정관리 가능하신 분</li>
                              <li>제안서 작성, 기술협의, 기술자료 및 리포트 작성 가능하신 분</li>
                              <li>전략적/논리적 사고 및 유연한 커뮤니케이션 가능하신 분</li>
                              <li>협업할 수 있는 커뮤니케이션 능력 및 관련 지식</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>
                                  UX, HCI, 서비스디자인, HCI, 인지과학 관련 전공자 혹은 석사학위
                                  이상
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button @click="goSubmit" type="button"><span>지원하기</span></button>
                        </div>
                      </div>
                    </li>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Planner</div>
                  </template>
                  <template slot="accordion-content">
                    <li class="acc-expend">
                      <div class="title">
                        데이터를 기반으로 분석하고,<br />
                        크레이티브를 언어로 다룰줄 알며,<br />
                        기술에 기반한 설득의 논리를 가진<br />
                        기획자를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img src="../../assets/images/career/planner.webp" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>온라인 서비스 모바일/웹/APP 분야에 관심이 있으신 분</li>
                              <li>신규 온라인 서비스에 대한 관심과 열정이 있으신 분</li>
                              <li>원활한 커뮤니케이션으로 배려와 설득에 자신 있으신 분</li>
                              <li>다양한 사고를 통해 아이디어를 구조화할 수 있으신 분</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>웹에이전시/SI 경험(또는 관련 전공자)</li>
                                <li>
                                  제안서 작성(트렌드 및 현황분석, 전략/방안 수립) 및 PT 가능하신 분
                                </li>
                                <li>마케팅 관련 기획 가능하신 분</li>
                                <li>다국어(영어, 일어, 중국어 등) 커뮤니케이션 가능하신 분</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>유관 경력 5년 이상</li>
                              <li>PC/Mobile UIUX 설계/기획 경력</li>
                              <li>Admin, Back-end 설계/기획 경력</li>
                              <li>서비스 및 관련 정책 기획 가능하신 분</li>
                              <li>다양한 분석을 통한 인사이트 도출 가능하신 분</li>
                              <li>고객사 및 유관 파트와 원활한 커뮤니케이션 능력</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>PM, 기획PL 경력</li>
                                <li>웹에이전시/SI 경력 우대</li>
                                <li>
                                  제안서 작성(트렌드 및 현황분석, 전략/방안 수립) 및 PT 가능하신 분
                                </li>
                                <li>마케팅 관련 기획 가능하신 분</li>
                                <li>다국어(영어, 일어, 중국어 등) 커뮤니케이션 가능하신 분</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button @click="goSubmit" type="button"><span>지원하기</span></button>
                        </div>
                      </div>
                    </li>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Designer</div>
                  </template>
                  <template slot="accordion-content">
                    <li class="acc-expend">
                      <div class="title">
                        우리는 열정이 가득하고<br />
                        크리에이티브한<br />
                        디자이너를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img src="../../assets/images/career/designer.webp" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>온라인 서비스 IT/Design 분야에 대한 관심과 열정이 있는 분</li>
                              <li>디자인 기획력/컨셉 도출 능력이 뛰어나신 분</li>
                              <li>원활한 커뮤니케이션 보유자</li>
                            </ul>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>디자인 업무 리딩 경험자</li>
                              <li>
                                온라인 서비스 IT/Design 분야에 대한 깊은 이해와 감각이 있는 분
                              </li>
                              <li>디자인 기획력/컨셉 도출 능력이 뛰어나신 분</li>
                              <li>클라이언트 및 부서간 원활한 커뮤니케이션 능력</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>디자인 업무 리딩 경험</li>
                                <li>
                                  온라인 서비스 IT/Design 분야에 대한 깊은 이해와 감각이 있는 분
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button @click="goSubmit" type="button"><span>지원하기</span></button>
                        </div>
                      </div>
                    </li>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Publisher</div>
                  </template>
                  <template slot="accordion-content">
                    <li class="acc-expend">
                      <div class="title">
                        우리는 높은 이해력과<br />
                        유연한 사고를 가진<br />
                        퍼블리셔를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img src="../../assets/images/career/publisher.webp" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>html, css, javascript를 이해하고 업무에 활용 가능자</li>
                              <li>웹 표준 및 웹 접근성 관련 개념을 갖추고 계신 분</li>
                              <li>형상관리(Git)의 작동원리를 이해하고 사용 가능한 분</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>
                                  vuejs와 react 등 SPA 프레임워크에 대한 개념을 갖추고 계신 분
                                </li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>
                                frontend 테스트 및 배포 자동화 환경에 대한 이해를 갖추고 계신 분
                              </li>
                              <li>frontend 개발 관련 효과적인 커뮤니케이션 가능자</li>
                            </ul>
                            <div class="preference">
                              <div class="txt">우대사항</div>
                              <ul>
                                <li>vuejs와 react 등 SPA 프레임워크를 활용한 개발 가능자</li>
                              </ul>
                            </div>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button @click="goSubmit" type="button"><span>지원하기</span></button>
                        </div>
                      </div>
                    </li>
                  </template>
                </accordion-item>

                <accordion-item>
                  <template slot="accordion-trigger">
                    <div class="acc-tit">Developer</div>
                  </template>
                  <template slot="accordion-content">
                    <li class="acc-expend">
                      <div class="title">
                        우리는 디테일한 부분까지<br />
                        완전함을 추구하는<br />
                        디벨로퍼를 기다립니다.
                      </div>
                      <div class="thumb-img">
                        <img src="../../assets/images/career/developer.webp" alt="" />
                      </div>
                      <div class="content">
                        <dl>
                          <dt>신입</dt>
                          <dd>
                            <ul>
                              <li>Java, .net 개발 가능자 및 전산 관련 전공자</li>
                              <li>프로그램 개발 학원 수료자(Java,.net)</li>
                              <li>Linux, Windows 기반 개념이 있으신 분</li>
                              <li>
                                최신 기술 및 트랜드에 대한 관심과 익히고자 하는 열망이 있으신 분
                              </li>
                            </ul>
                          </dd>
                        </dl>
                        <dl>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li>Java Spring Framework, Maven 개발 가능자</li>
                              <li>Oracle, MSSQL, MYSQL, MariaDB 설계 가능자 및 경험자</li>
                              <li>Python, NodeJS, Angular, React 경험이 있거나 관심이 있으신 분</li>
                              <li>Github, Jenkins 경험자</li>
                              <li>최신 트랜드에 대한 관심과 개발에 대한 거부감이 없으신 분</li>
                            </ul>
                          </dd>
                        </dl>
                        <div class="careers-btn">
                          <button @click="goSubmit" type="button"><span>지원하기</span></button>
                        </div>
                      </div>
                    </li>
                  </template>
                </accordion-item>
              </accordion>
            </div>
            <div class="careers-form-wrap">
              <form class="careers-form" @submit.prevent="uploadFile" ref="form">
                <h3 class="title">지원 정보를 입력해주세요.</h3>

                <fieldset>
                  <legend>지원자 정보</legend>
                  <ul class="form-list flex-box">
                    <li>
                      <input
                        type="text"
                        placeholder="이름"
                        v-model="formData.applicantName"
                        :maxlength="maxlength"
                      />
                    </li>
                    <li>
                      <input
                        type="number"
                        placeholder="전화번호"
                        v-model="formData.applicantNumber"
                      />
                    </li>
                    <li>
                      <input
                        type="email"
                        placeholder="이메일"
                        v-model="formData.applicantEmail"
                        :maxlength="maxlength"
                        ref="emailInput"
                      />
                      <span v-show="isError">이메일 형식이 유효하지 않습니다.</span>
                    </li>

                    <li>
                      <input
                        type="text"
                        placeholder="(선택) 참조 사이트 주소"
                        v-model="formData.applicantUrl"
                      />
                    </li>
                  </ul>
                </fieldset>

                <fieldset>
                  <legend>지원 분야</legend>
                  <div class="flex-box">
                    <ul class="form-list">
                      <li v-for="item in supportingField.list" :key="item.value">
                        <form-radio
                          :disabled="item.disabled"
                          :val="item.value"
                          name="supportingField"
                          v-model="supportingField.value"
                        >
                          {{ item.label }}
                        </form-radio>
                      </li>
                    </ul>

                    <form-select
                      style="width: 358px"
                      placeholder="지원분야를 선택해주세요."
                      required
                      v-model="supportingSelect.value"
                      :options="supportingSelect.options"
                    />
                  </div>
                </fieldset>

                <fieldset>
                  <legend>지원 내용</legend>
                  <div class="textarea">
                    <TextareaAutosize
                      placeholder="지원 동기 등 자유롭게 작성해 주세요."
                      v-model="formData.applicantContents"
                      :min-height="270"
                      :max-height="270"
                    />
                    <ul
                      :class="{
                        'file-list': true,
                        'one-line': uploadFiles.length === 1
                      }"
                    >
                      <!-- 한줄일때 one-line -->
                      <li v-for="(item, index) in uploadFiles" :key="index">
                        <span>{{ item.name }}({{ fileSize(item.size) }}MB)</span>
                        <button type="button" class="del" @click="fileDelete(index)">삭제</button>
                      </li>
                    </ul>
                    <div class="file-add">
                      <span class="file-btn">
                        <button type="button">파일첨부</button>
                        <input
                          type="file"
                          multiple
                          @change="fileChange"
                          accept=".pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpg, .png, .zip"
                        />
                      </span>
                      <p class="alert">
                        이력서, 포트폴리오 등 5개까지 업로드 가능 (최대 10MB) pdf, ppt, word, excel,
                        jpg, png
                      </p>
                    </div>
                  </div>
                </fieldset>

                <privacy :dialogVisible.sync="dialogVisible" />
                <div class="agree-btn-wrap">
                  <input type="checkbox" id="agree" v-model="formData.privacy" />
                  <label for="agree"
                    ><a href="#" @click.prevent="dialogVisible = true">개인정보보호정책</a>에
                    동의합니다.
                  </label>
                </div>
                <div class="careers-btn submit-btn">
                  <button type="submit" :disabled="validate" @click="checkEmail($event)">
                    <span>지원하기</span>
                  </button>
                </div>
              </form>
              <div class="careers-info">
                <div class="info-inner">
                  저는
                  <span :class="{active: !this.formData.applicantName.length}">{{ name }}</span
                  >입니다. <br />
                  제 번호는
                  <span :class="{active: !this.formData.applicantNumber.length}">{{ number }}</span
                  >고, <br />
                  제 메일은
                  <span :class="{active: !this.formData.applicantEmail.length}">{{ email }}</span
                  >입니다. <br />
                  <br />
                  <span :class="{active: !this.supportingField.value}">{{ supportFieldValue }}</span
                  >으로<br />
                  <span :class="{active: !this.supportingSelect.value}">{{
                    supportSelectValue
                  }}</span
                  >에 지원합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Apply// -->
      </div>
    </div>
  </div>
</template>
<script>
  import {TweenMax} from "gsap/all";
  import {mapGetters} from "vuex";
  import Accordion from "@/components/global/accordion";
  import AccordionItem from "@/components/global/accodion-item";
  import {apiCode, apiFile, apiCareer, apiCareerController} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import bus from "@/utils/bus";
  import privacy from "@/views/privacy/index";
  import {Power0} from "gsap";

  export default {
    name: "Careers",
    metaInfo() {
      return {
        title: "Careers",
        titleTemplate: "%s | e · motion",
        meta: [
          {
            property: "og:title",
            content: "Careers | e · motion",
            vmid: "og:title"
          },
          {
            property: "og:url",
            content: "https://www.emotion.co.kr/careers/",
            vmid: "og:url"
          }
        ]
      };
    },
    mixins: [commonMethods],
    components: {
      Accordion,
      AccordionItem,
      privacy
    },
    data() {
      return {
        isError: false,
        maxlength: 40,
        tab: {
          name: "tab",
          list: [
            {
              id: "culture",
              title: "Culture"
            },
            {
              id: "apply",
              title: "Apply"
            }
          ],
          active: "culture"
        },
        dialogVisible: false,
        peopleName: "DESIGN",
        swiperPanorama: {
          observer: true,
          observerParents: true,
          loop: true,
          autoplay: {
            delay: 3000
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
          }
        },
        scene: null,
        scene1: null,
        scene2: null,
        maxFileLength: 5,
        supportingField: {
          list: [],
          value: ""
        },
        supportingSelect: {
          options: [],
          value: null
        },
        uploadFiles: [],
        formData: {
          applicantName: "",
          applicantNumber: "",
          applicantEmail: "",
          applicantContents: "",
          experience: "",
          applicantPosition: "",
          applicantUrl: "",
          privacy: false,
          fileList: []
        },
        careerController: {}
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"]),
      validate() {
        return (
          !this.formData.experience ||
          !this.formData.applicantPosition ||
          !this.formData.applicantName ||
          !this.formData.applicantEmail ||
          !this.formData.applicantNumber ||
          !this.formData.applicantContents ||
          !this.formData.privacy
        );
      },
      isValid() {
        const regExp = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return regExp.test(this.formData.applicantEmail);
      },
      name() {
        return this.txt(this.formData.applicantName, "이름");
      },
      number() {
        const num = this.formData.applicantNumber
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

        return this.txt(num, "000-0000-0000");
      },
      email() {
        return this.txt(this.formData.applicantEmail, "이메일");
      },
      supportFieldValue() {
        const value = this.txt(this.supportingField.value, "신입 / 경력");
        if (value === "WET_NEW") {
          return "신입";
        } else if (value === "WET_EXPERIENCE") {
          return "경력";
        } else {
          return value;
        }
      },
      supportSelectValue() {
        const value = this.txt(this.supportingSelect.value, "지원 분야");
        if (this.supportingSelect.value) {
          const str = value.replace("PST_", "").toLowerCase();
          const newStr = str.replace(/^[a-z]/, char => char.toUpperCase());
          return newStr;
        } else {
          return value;
        }
      }
    },
    watch: {
      $route() {
        let y = window.scrollY;
        let tabY = document.querySelector(".tab-contents").offsetTop;
        if (tabY < y) {
          window.scrollTo({top: tabY + 1});
        }
      },
      isValid() {
        if (this.isValid) {
          this.isError = false;
        }
      },
      "formData.applicantNumber"(n, o) {
        if (n.length > 11) {
          this.formData.applicantNumber = o;
        }
      },
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
          this.tabFixed();
        }
      },
      "tab.active"(val1) {
        if (val1 === "apply") {
          this.$nextTick(() => {
            this.careerInfoFixed();
          });
        }
      },
      "supportingSelect.value"(val) {
        this.formData.applicantPosition = val;
      },
      "supportingField.value"(val) {
        this.formData.experience = val;
      }
    },
    activated() {
      this.tabFixed();
    },
    mounted() {
      this.init();
      this.fetchCareerSwiper();
      this.code("WORK_EXPERIENCE_TYPE", this.supportingField.list);
      this.code("POSITION_TYPE", this.supportingSelect.options);
    },
    methods: {
      init() {
        this.scene1?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        this.scene1 = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2]);
        this.$scrollmagic.addScene(this.scene1);
      },
      // 이메일 유효성 체트
      checkEmail(e) {
        if (!this.isValid) {
          e.preventDefault();
          this.isError = true;
          this.goSubmit();
          this.$refs.emailInput.focus();
        } else {
          this.isError = false;
        }
      },
      // 텍스트 상태
      txt(a, b) {
        return a ? a : b;
      },
      /* // 탭 고정 */
      tabFixed() {
        const item = document.querySelector(".tab-top");
        const contents = document.querySelector(".tab-contents");
        if (item.style.position === "fixed") return;
        const scene1 = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0.1,
            duration: contents.clientHeight - "100vh" || 0
          })
          .setPin(item)
          .setClassToggle(".tab-top", "flex-end");
        this.$scrollmagic.addScene(scene1);
        /* // 탭 고정 */
      },
      /* // careers-info 고정 */
      careerInfoFixed() {
        this.scene2?.destroy(true);
        const item = document.querySelector(".careers-info");
        const inner = item.querySelector(".info-inner");
        const tween = TweenMax.to(inner, 1, {
          ease: Power0.easeInOut,
          y: item.clientHeight - inner.clientHeight
        });
        this.scene2 = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0,
            duration: item.clientHeight - inner.clientHeight
          })
          .setClassToggle(".careers-info", "pt-100")
          .setTween(tween);
        this.$scrollmagic.addScene(this.scene2);
      },
      goSubmit() {
        window.scrollTo({
          top: document.querySelector(".careers-form").offsetTop
        });
      },
      fileSize(size) {
        let value = size / 1024 / 1024;
        let exp = -1;
        if (typeof exp === "undefined" || +exp === 0) {
          return Math.ceil(value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
          return NaN;
        }
        value = value.toString().split("e");
        value = Math.ceil(+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
        value = value.toString().split("e");
        return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
      },
      fileDelete(index) {
        //console.log(index);
        this.uploadFiles.splice(index, 1);
      },
      fileChange(e) {
        //console.log(e);
        const files = e.target.files || e.dataTransfer.files;
        if (this.uploadFiles.length + files.length > this.maxFileLength) {
          alert(`최대 ${this.maxFileLength}개 가능`);
          return;
        }
        files.forEach(el => {
          if (el.size <= 1024 * 1024 * 10) {
            this.uploadFiles.push(el);
          } else {
            alert("최대 50MB");
          }
        });
      },
      async uploadFile() {
        this.$gtag.event("Click", {
          button: "button1",
          page: "page1"
        });
        bus.$emit("loading", true);
        Promise.all(
          this.uploadFiles.map(async el => {
            try {
              const formData = new FormData();
              formData.append("files", el);
              const {
                data: {data: response}
              } = await apiFile("career", formData);
              this.formData.fileList.push({
                careerFileName: response[0].originName,
                careerPhysicalFileName: response[0].physicalName
              });
            } catch (e) {
              console.log(e);
            }
          })
        )
          .then(() => {
            this.submit();
          })
          .catch(e => {
            bus.$emit("loading", false);
            console.log(e);
          });
      },
      async code(code, targetData) {
        try {
          const {
            data: {data: response}
          } = await apiCode(code);
          //console.log(response);
          response.forEach(el => {
            targetData.push({
              value: el.commonCode,
              label: el.commonCodeDescription
            });
          });
          //console.log(response);
        } catch (e) {
          console.log(e);
          return [];
        }
      },
      async submit() {
        try {
          const {data: response} = await apiCareer(this.formData);
          if (response.code === "SUCCESS") {
            await this.$refs.form.reset();
            alert("지원이 완료 되었습니다.");
            this.formData = {
              experience: "",
              applicantPosition: "",
              applicantName: "",
              applicantEmail: "",
              applicantNumber: "",
              applicantContents: "",
              applicantUrl: "",
              privacy: false,
              fileList: []
            };
            this.supportingField.value = null;
            this.supportingSelect.value = null;
            this.uploadFiles = [];
          } else {
            alert(response.message);
          }
          await bus.$emit("loading", false);
        } catch (error) {
          await bus.$emit("loading", false);
          console.log(error);
        }
      },
      async fetchCareerSwiper() {
        try {
          const {
            data: {data: response}
          } = await apiCareerController({
            response: true,
            alert: true,
            code: "",
            message: ""
          });
          //console.log(response);
          this.careerController = response;
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .tab-wrap {
    margin-top: 100vh;
  }
  ::v-deep .tab-top {
    .tab-list {
      ul {
        width: 344px;
      }
    }
  }
  .tab-contents {
    padding: 150px 0 160px;
  }
  .careers > .contents {
    width: calc(100vw - 15px);
    padding: 0;
    background-color: #ddd;
    overflow: hidden;
  }
  .key-visual {
    position: relative;
    min-height: 1000px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100vh;
      position: relative;
      overflow: hidden;
    }
    .contents {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
    }
    .bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url(../../assets/images/career/img_careers_main.webp) center center / cover
        no-repeat;
    }
    .txt {
      margin-top: 120px;
      height: 540px;
      padding-left: 20px;
      left: 0;
      top: 0;
      font-weight: 700;
      font-size: 70px;
      line-height: 100px;
      letter-spacing: -0.03em;
    }
  }
  .careers-inner {
    padding: 150px 0 120px;
    .title,
    .sub-title {
      font-family: "Noto Sans KR", sans-serif;
      font-weight: 700;
      letter-spacing: -0.03em;
      strong {
        color: $red;
      }
    }
    .title {
      color: #a0a0a0;
      font-size: 48px;
      line-height: 74px;
      letter-spacing: -0.065em;
    }
    .sub-title {
      margin-top: 120px;
      font-size: 44px;
      line-height: 44px;
    }
    .panorama-swiper {
      width: 100%;
      height: 528px;
      margin-top: 120px;
      .thumb {
        display: block;
      }
      ::v-deep.swiper-pagination-bullets {
        bottom: 0;
        .swiper-pagination-bullet {
          margin: 0 5px;
          background: #ddd;
          opacity: 1;
          transition: width ease-in-out 0.2s;
          &.swiper-pagination-bullet-active {
            width: 28px;
            background: #ee2c3c;
            border-radius: 4px;
            opacity: 1;
          }
        }
      }
    }

    .benefits-list {
      margin: 92px 0 0 120px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      li {
        flex: 0 0 255px;
        margin-left: 28px;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        &:nth-child(n + 5) {
          margin-top: 60px;
        }

        strong {
          display: block;
          margin-top: 20px;
          font-family: "Noto Sans KR", sans-serif;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.05em;
        }
        p {
          margin-top: 17px;
          color: #888;
          font-family: "Noto Sans KR", sans-serif;
          line-height: 1.5;
          font-size: 14px;
          letter-spacing: -0.05em;
        }
      }
    }
    .inner {
      min-width: $globalWidth;
      background: #eb1624;
      margin-top: 120px;

      .careers-process {
        position: relative;
        width: $globalWidth;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 69px 20px 128px;
        .title {
          color: #fff;
        }
        .sub-txt {
          position: absolute;
          top: 150px;
          right: 20px;
          font-size: 14px;
          color: #fff;
          line-height: 30px;
          .mail {
            span {
              @include montserrat();
              margin-left: 30px;
              color: #fff;
            }
          }
        }
        .careers-process-step {
          display: flex;
          margin-top: 80px;
          li {
            position: relative;
            width: 216px;
            height: 216px;
            border: 2px solid #fff;
            border-radius: 50%;
            & + li {
              margin-left: 120px;
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: -120px;
                transform: translateY(-50%);
                width: 90px;
                height: 16px;
                background: url(../../assets/images/right_arrow_w.png) no-repeat 0 0/100%;
              }
            }

            span {
              display: block;
              margin-top: 45px;
              text-align: center;
              @include montserrat();
              font-size: 16px;
              color: #fb838b;
            }
            p {
              margin-top: 35px;
              font-size: 30px;
              text-align: center;
              color: #fff;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .careers-btn {
    margin: 80px auto 0;
    text-align: center;
    &.submit-btn {
      margin-top: 120px;
    }
    button {
      display: block;
      margin: 0 auto;
      width: 240px;
      height: 80px;
      background: #ee2c3c;
      border-radius: 40px;
      text-align: center;
      span {
        color: #fff;
        font-family: "Noto Sans KR", sans-serif;
        line-height: 35px;
        font-size: 30px;
        font-weight: bold;
      }
    }
    button:disabled {
      background: #ddd;
    }
  }
  .job-type {
    margin: auto 0;
    > .title {
      color: #a0a0a0;
    }
    .accordion {
      margin-top: 60px;
    }
    .acc-tit {
      position: relative;
      width: 100%;
      padding: 54px 0;
      @include montserrat();
      font-size: 32px;
      font-weight: 700;
      transition: color 300ms ease-in-out 0s;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        width: 30px;
        height: 15px;
        background: url(../../assets/images/career/acc-off.svg) no-repeat center/cover;
      }
    }
    .acc-expend {
      position: relative;
      padding: 0 120px 80px;
      .title {
        padding-top: 70px;
        font-size: 44px;
        font-weight: 700;
        color: #000;
      }
      .thumb-img {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 336px;
      }
      .content {
        margin-top: 244px;
        dl {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          padding-left: 96px;
          & + dl {
            margin-top: 80px;
          }
          dt {
            width: 80px;
            margin-top: 5px;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
          }
          dd {
            width: 768px;
            margin-left: 40px;
            ul {
              li {
                position: relative;
                padding-left: 13px;
                font-size: 18px;
                color: #333;
                & + li {
                  margin-top: 20px;
                }
                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  width: 3px;
                  height: 3px;
                  background: #333;
                  border-radius: 50%;
                }
              }
            }
            .preference {
              position: relative;
              display: flex;
              margin: 70px 0 0 13px;
              &:before {
                content: "";
                position: absolute;
                top: -20px;
                left: 0;
                width: 12px;
                height: 12px;
                background: #ee2c3c;
                border-radius: 50%;
              }
              .txt {
                width: 80px;
                font-size: 14px;
                color: #333;
                line-height: 30px;
                font-weight: bold;
              }
              ul {
                li {
                  color: #888;
                  font-size: 14px;
                  line-height: 30px;
                  & + li {
                    margin: 0;
                  }
                  &:before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .careers-form-wrap {
    display: flex;
    margin-top: 120px;
    .careers-form {
      flex: 1 1 776px;
      margin-right: 120px;
      > .title {
        font-size: 44px;
        line-height: 44px;
        color: #000;
      }
      fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:first-of-type {
          margin-top: 120px;
        }

        & + fieldset {
          margin-top: 80px;
        }
        legend {
          font-size: 24px;
          font-weight: bold;
          line-height: 40px;
          letter-spacing: -0.05em;
          margin-bottom: 30px;
        }
      }
      .form-list {
        display: flex;
        li {
          width: 182px;
          height: 48px;
          label {
            height: 48px;
            line-height: 1;
          }
          & + li {
            margin-left: 16px;
          }
          input[type="number"] {
            width: 100%;
            height: auto;
            padding: 12px;
            line-height: 28px;
            border: none;
            border-bottom: 1px solid #ddd;
            box-sizing: border-box;
            &::placeholder {
              color: #b4b4b4;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          span {
            display: inline-block;
            margin: 10px;
            font-size: 14px;
            color: $red;
          }
        }
        label.checked {
          border-color: #ee2c3c;
          background: #ee2c3c;
          color: #fff;
        }
        &.flex-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            flex: 0 0 calc((100% - 60px) / 2);
            & + li {
              margin-left: 0;
            }
            &:nth-child(n + 3) {
              margin-top: 22px;
            }
          }
        }
      }
      .form-select {
        ::v-deep ul {
          li {
            cursor: pointer;
            &:hover {
              background-color: #f6f6f6;
            }
          }
        }
      }
      .flex-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
      }
      .textarea {
        width: 100%;
        margin-top: 0;
        padding: 20px 30px 17px;
        border: 1px solid #ddd;
        textarea {
          width: 100%;
          resize: none;
          padding: 0;
          border: 0;
          outline: none;
          display: block;
        }
        .file-list {
          display: flex;
          margin-top: 30px;
          flex-wrap: wrap;
          li {
            flex: 0 0 auto;
            width: calc((100% - 2px) / 2);
            position: relative;
            box-sizing: border-box;
            padding: 16px 50px 16px 18px;
            line-height: 18px;
            font-size: 14px;
            color: #333;
            background: #f6f6f6;
            span {
              display: inline-block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: top;
            }
            .del {
              position: absolute;
              top: 10px;
              right: 10px;
              height: 30px;
              width: 30px;
              text-indent: -9999px;
              background: url("../../assets/images/btn_delete.png") no-repeat 50%;
            }
            &:nth-child(n + 3) {
              margin-top: 2px;
            }
            &:nth-child(2n) {
              margin-left: 2px;
            }
          }
          &.one-line {
            li {
              width: 100%;
              margin: 0;
            }
          }
        }
        .file-add {
          display: flex;
          margin-top: 18px;
          align-items: center;
          .file-btn {
            position: relative;
            overflow: hidden;
            display: inline-block;
            button {
              height: 38px;
              width: 89px;
              border-radius: 19px;
              border: none;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              background: #333;
            }
            input[type="file"] {
              z-index: 2;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
          .alert {
            position: relative;
            margin-left: 20px;
            padding-left: 25px;
            color: #888;
            line-height: 20px;
            font-size: 14px;
            &:after {
              content: "!";
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              font-size: 14px;
              font-family: "Montserrat", sans-serif;
              line-height: 18px;
              color: #ddd;
              text-align: center;
              box-sizing: border-box;
              border-radius: 50%;
              border: 1px solid #ddd;
              background: #f9f9f9;
            }
          }
        }
      }

      .agree-btn-wrap {
        margin-top: 40px;
        input[type="checkbox"] {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
          &:checked + label:before {
            background: url("../../assets/images/check_btn_on.png") no-repeat 0 0/100%;
          }
        }
        label {
          position: relative;
          padding-left: 45px;
          font-size: 18px;
          color: #333;
          cursor: pointer;
          a {
            color: #ee2c3c;
            text-decoration: underline;
          }
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 26px;
            height: 26px;
            background: url("../../assets/images/check_btn.png") no-repeat 0 0/100%;
          }
        }
      }
    }
    .careers-info {
      width: 328px;
      transition: padding 300ms;
      &.pt-100 {
        padding-top: 100px;
      }
      .info-inner {
        padding: 48px 0;
        will-change: transform;
        font-weight: 700;
        font-size: 24px;
        line-height: 42px;
        color: #333;
        letter-spacing: -0.05em;
        word-break: keep-all;

        &:before {
          content: "";
          position: absolute;
          top: 14px;
          left: 0;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background: $red;
        }

        span {
          color: $red;
          word-break: break-word;
          &.active {
            color: #b4b4b4;
          }
        }
      }
    }
  }
</style>
